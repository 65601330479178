import devConfig from './dev';
import prodConfig from './prod';
import uatConfig from './uat';

const configs = {
  dev: devConfig,
  uat: uatConfig,
  prod: prodConfig,
};

export default configs[process.env.REACT_APP_ENV] || devConfig;
