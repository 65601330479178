import { initApp } from '../libs/libs';
import AppRouter from './AppRouter';
import useStore from '../hooks/useStore';

import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

let manifestJSON = {
  name: 'Wallet Pass +',
  short_name: 'Wallet Pass +',
  display: 'browser',
  icons: [
    {
      src: 'https://pwa-dynamic-manifest.pablo74.repl.co/img/icon_192_192.png',
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: 'https://pwa-dynamic-manifest.pablo74.repl.co/img/icon_512_512.png',
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  background_color: '#FFFFFF',
  theme_color: '#027be3',
};

export default function Main() {
  const { init, dispatch, lang } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [loadMainError, setLoadMainError] = useState(false);

  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const setAppConfig = ({ name, icon }) => {
    const appName = name ?? 'Wallet Plus';
    const appIcon = icon ?? 'wallet_plus_icon_192.png';
    const webAppIcons = [
      {
        src: appIcon,
        sizes: '192x192',
        type: 'image/png',
      },
      {
        src: icon ?? 'wallet_plus_icon_512.png',
        sizes: '512x512',
        type: 'image/png',
      },
    ];

    manifestJSON.name = appName;
    manifestJSON.short_name = appName;
    manifestJSON.icons = webAppIcons;

    const stringManifest = JSON.stringify(manifestJSON);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector('#manifest-placeholder')
      .setAttribute('href', manifestURL);
    document.querySelector('#app-icon').setAttribute('href', appIcon);
    document.title = appName;
  };

  useEffect(
    () => {
      const loadData = async () => {
        try {
          await initApp({
            dispatch: dispatch,
            urlToken: searchParams.get('wallet_login_token'),
            clientId: searchParams.get('client_id'),
          });
          if (location.pathname === '/login') {
            navigate('/');
          }
        } catch (error) {
          console.log(error);
          setLoadMainError(true);
        }
      };
      if (!init) {
        loadData();
      }
      const program = JSON.parse(localStorage.getItem('program'));
      setAppConfig({
        name: program?.app_title[lang],
        icon: program?.app_icon?.url,
      });
    },
    [init, dispatch],
  );

  useEffect(
    () => {
      if (loadMainError) {
        navigate('/noPermission', { replace: true });
        setLoadMainError(false);
      }
    },
    [loadMainError],
  );

  return <AppRouter />;
}
