import { createTheme } from "@material-ui/core";
import Montserrat from "../assets/fonts/Montserrat-Regular.ttf";
import MontserratBold from "../assets/fonts/Montserrat-SemiBold.ttf";

const montserrat = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${Montserrat}) format('truetype')
  `,
};

const montserratBold = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    local('Montserrat'),
    local('Montserrat-Bold'),
    url(${MontserratBold}) format('truetype')
  `,
};

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#FFFFFF",
      darker: "#4F4F4D",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [montserrat, montserratBold],
      },
    },
  },
});

export default theme;
