import { Card, CardMedia, makeStyles } from '@material-ui/core';
import { formatDate } from '../../../../libs/date';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import React from 'react';
import colors from '../../../../style/colors';
import useStore from '../../../../hooks/useStore';

const useStyle = makeStyles({
  container: {
    marginBottom: 32,
  },
  image: {
    minHeight: 171,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
    marginBottom: 8,
  },
  imageContent: {
    objectFit: 'cover',
    minHeight: 171,
    backgroundColor: colors.grey,
  },
  titleContainer: {
    marginBottom: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grey50,
  },
  textButton: {
    alignItems: 'center',
    display: 'flex',
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 600,
    textDecoration: 'underline',
    color: colors.branding,
  },
});

export default function PromotionItem(props) {
  const classes = useStyle();
  const { data } = props;
  const { lang } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getSubtitle = () => {
    if (data?.publish_at && data?.expire_at) {
      return `${formatDate(data?.publish_at, lang)} - ${formatDate(
        data?.expire_at,
        lang,
      )}`;
    } else if (data?.publish_at) {
      return t('promotion.startAfter', {
        date: formatDate(data?.publish_at, lang),
      });
    } else if (data?.expire_at) {
      return t('promotion.endBefore', {
        date: formatDate(data?.expire_at, lang),
      });
    }
  };

  return (
    <div
      className={classes.container}
      onClick={() => {
        navigate('/promotion', {
          state: { data: data },
        });
      }}
    >
      <Card className={classes.image}>
        {data?.image[lang]?.url && (
          <CardMedia
            className={classes.imageContent}
            component="img"
            image={data?.image[lang]?.url}
            alt="image"
          />
        )}
      </Card>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{data?.title[lang]}</span>
        <br />
        <span className={classes.subtitle}>{getSubtitle()}</span>
      </div>
      <div className={classes.textButton}>
        <span className={classes.buttonText}>{t('promotion.readMore')}</span>
        <NavigateNextOutlinedIcon className={classes.buttonText} />
      </div>
    </div>
  );
}
