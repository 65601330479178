import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Route, Routes, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ErrorDialog from '../dialog/ErrorDialog';
import ErrorPage from './ErrorPage';
import Home from './Home';
import MissionDetail from './MissionDetail';
import MyWallet from './MyWallet';
import NewsAndPromotion from './NewsAndPromotion';
import Notification from '../service/firebase/Notification';
import PromotionDetail from './PromotionDetail';
import React from 'react';
import RewardDetail from './RewardDetail';
import TransactionHistory from './TransactionHistory';

const useStyle = makeStyles({
  page: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  enter_fade: {
    opacity: 0,
  },
  enterActive_fade: {
    opacity: 1,
    transition: 'all 400ms ease-in',
  },
  exit_fade: {
    opacity: 0,
    transition: 'all 400ms ease-in',
  },
  exitActive_fade: {
    opacity: 0,
    transition: 'all 400ms ease-in',
  },
  enter_right: {
    transform: 'translateX(100%)',
  },
  enterActive_right: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-in',
  },
  exit_right: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-in',
  },
  exitActive_right: {
    transform: 'translateX(-100%)',
    transition: 'all 300ms ease-in',
  },
  enter_left: {
    transform: 'translateX(-100%)',
  },
  enterActive_left: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-in',
  },
  exit_left: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-out',
  },
  exitActive_left: {
    transform: 'translateX(100%)',
    transition: 'all 300ms ease-out',
  },
});

export default function AppRouter() {
  const classes = useStyle();
  const location = useLocation();
  // const navigationType = useNavigationType();

  const transitionFade = {
    enter: classes.enter_fade,
    enterActive: classes.enterActive_fade,
    exit: classes.exit_fade,
    exitActive: classes.exitActive_fade,
  };

  // const transitionRight = {
  //   enter: classes.enter_right,
  //   enterActive: classes.enterActive_right,
  //   exit: classes.exit_right,
  //   exitActive: classes.exitActive_right,
  // };

  // const transitionLeft = {
  //   enter: classes.enter_left,
  //   enterActive: classes.enterActive_left,
  //   exit: classes.exit_left,
  //   exitActive: classes.exitActive_left,
  // };

  // const getTransition = () => {
  //   if (navigationType === 'PUSH') {
  //     console.log('push');
  //     return transitionRight;
  //   } else if (navigationType === 'POP') {
  //     console.log('pop');
  //     return transitionLeft;
  //   }
  // };

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames={transitionFade}
          timeout={400}
        >
          <div className={classes.page}>
            <Routes location={location}>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/login" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/mission" element={<MissionDetail />} />
              <Route exact path="/reward" element={<RewardDetail />} />
              <Route
                exact
                path="/transaction"
                element={<TransactionHistory />}
              />
              <Route
                exact
                path="/promotionList"
                element={<NewsAndPromotion />}
              />
              <Route exact path="/promotion" element={<PromotionDetail />} />
              <Route exact path="/wallet" element={<MyWallet />} />
              <Route exact path="/noPermission" element={<ErrorPage />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <ErrorDialog />
      <Notification />
    </>
  );
}
