import { makeStyles } from '@material-ui/core';
import React from 'react';
import colors from '../../style/colors';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    marginTop: 74,
    marginBottom: 24,
  },
  pageTitle: {
    color: colors.branding,
    fontWeight: 600,
  },
});

export default function CommonPageTitle(props) {
  const classes = useStyles();
  const { title = '', style, largeTitle = false } = props;

  return (
    <div className={classes.container} style={{ ...style }}>
      <span
        style={{ fontSize: largeTitle ? 20 : 14 }}
        className={classes.pageTitle}
      >
        {title}
      </span>
    </div>
  );
}
