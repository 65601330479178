import { CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonAppBar from '../../components/CommonAppBar';
import CommonFooter from '../../components/CommonFooter';
import CommonPageTitle from '../../components/CommonPageTitle';
import InfiniteScroll from 'react-infinite-scroll-component';
import PromotionItem from './component/PromotionItem';
import React, { useEffect, useState } from 'react';
import colors from '../../style/colors';
import memberApi from '../../libs/api/memberApi';
import useStore from '../../hooks/useStore';

const useStyle = makeStyles({
  container: {
    margin: 24,
  },
  loaderContainer: {
    textAlign: 'center',
    margin: 16,
  },
  loader: {
    color: colors.grey50,
  },
  scrollContainer: {
    paddingBottom: 48,
  },
});

export default function NewsAndPromotion() {
  const classes = useStyle();
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();

  const { init, dispatch } = useStore();

  const pageSize = 10;

  const checkPageEnd = (data) => {
    if (data.length < pageSize) {
      setHasMore(false);
    } else {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    }
  };

  const fetchData = async () => {
    try {
      const response = await memberApi.getPromotions({
        page: currentPage,
        pageSize: pageSize,
      });
      setData((prevData) => [...prevData, ...response]);

      checkPageEnd(response);
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
  };

  useEffect(
    () => {
      if (init) {
        fetchData();
      }
    },
    [init],
  );

  return (
    <>
      <CommonAppBar />
      <div className={classes.container}>
        <CommonPageTitle
          title={t('promotion.title')}
          style={{ textAlign: 'left' }}
        />
        <InfiniteScroll
          className={classes.scrollContainer}
          dataLength={data.length}
          next={() => {
            if (data.length > 0) {
              fetchData();
            }
          }}
          hasMore={hasMore}
          loader={
            <div className={classes.loaderContainer}>
              <CircularProgress size={30} className={classes.loader} />
            </div>
          }
          endMessage={<CommonFooter />}
        >
          {[...new Set(data)]?.map((item) => (
            <PromotionItem data={item} key={item.id} />
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
}
