import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import colors from '../../style/colors';
import styled from '@emotion/styled';

const useStyles = makeStyles({
  text: {
    fontSize: 14,
    fontWeight: 600,
  },
});

export default function CommonButton(props) {
  const {
    onClick,
    active = true,
    text,
    margin,
    backgroundColor,
    textColor,
    loading = false,
    minWidth,
    minHeight,
    padding,
    textStyle,
  } = props;
  const classes = useStyles();

  const ActiveButton = styled(Button)(() => ({
    boxShadow: 'none',
    backgroundColor:
      active && !loading ? backgroundColor ?? colors.branding : colors.disable,
    padding: padding ?? '16px 24px',
    minWidth: minWidth ?? 238,
    minHeight: minHeight ?? 52,
    borderRadius: 100,
    margin: margin,
    '&:hover': {
      backgroundColor: active
        ? backgroundColor ?? colors.branding
        : colors.disable,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: active
        ? backgroundColor ?? colors.branding
        : colors.disable,
    },
  }));

  return (
    <ActiveButton
      variant="contained"
      onClick={() => {
        if (active && !loading) {
          onClick();
        }
      }}
    >
      {!loading && (
        <span
          className={classes.text}
          style={{ color: textColor ?? colors.white, ...textStyle }}
        >
          {text}
        </span>
      )}
      {loading && <CircularProgress size={24} />}
    </ActiveButton>
  );
}
