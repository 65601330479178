import { getAnalytics } from '@firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import merchants from '../../merchants';

const firebaseConfig = merchants.firebaseConfig;

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();
export const analytics = getAnalytics(app);

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: merchants.vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
