import { analytics } from '../service/firebase/firebase';
import { logEvent } from '@firebase/analytics';
import i18n from '../i18n';
import memberApi from './api/memberApi';

async function initApp({ dispatch, urlToken, clientId }) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    let token = JSON.parse(localStorage.getItem('token'));
    let storageWalletToken = localStorage.getItem('walletToken');
    let needWalletLogin = false;
    if (urlToken || !token) {
      needWalletLogin = !token || storageWalletToken !== urlToken;
    }
    if (needWalletLogin) {
      token = await memberApi.walletLogin({
        hashToken: decodeURIComponent(urlToken),
        clientId: clientId,
      });
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('walletToken', urlToken);
    } else {
      memberApi.setToken(token);
    }
    let program = null;
    let me = null;
    let missions = null;
    let items = null;
    let redeemables = null;
    await Promise.all([
      memberApi.getProgram(),
      memberApi.getMe(),
      memberApi.getMissions({ page: 0, pageSize: 5 }),
      memberApi.getItems({ page: 0, pageSize: 6, status: 'usable' }),
      memberApi.getRedeemable({ page: 0, pageSize: 10 }),
    ])
      .then(
        async ([programRes, meRes, missionsRes, itemsRes, redeemablesRes]) => {
          program = programRes;
          me = meRes;
          missions = missionsRes;
          items = itemsRes;
          redeemables = redeemablesRes;
        },
      )
      .catch((error) => {
        throw Error(error);
      });
    const state = {
      init: true,
      me: me,
      missions: missions,
      redeemables: redeemables,
      items: items,
      lang: me?.preferences?.language ?? 'zh',
      program: program,
      tierStyle: {
        color: me?.tier?.theme?.textColor,
        backgroundColor: me?.tier?.theme?.bgColor,
      },
      token: token,
    };
    localStorage.setItem('program', JSON.stringify(program));
    await changeLocale({
      lang: me?.preferences?.language ?? 'zh',
      timezone: me?.preferences?.timezone,
      dispatch: dispatch,
    });
    dispatch({ type: 'INIT', payload: state });
    console.log(process.env, 'hi');
    logEvent(analytics, 'welcome', { name: 'hello' });
  } catch (error) {
    throw Error(error);
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

async function reload({ dispatch }) {
  try {
    let me;
    let missions;
    let items;
    let redeemables;
    await Promise.all([
      memberApi.getMe(),
      memberApi.getMissions({ page: 0, pageSize: 5 }),
      memberApi.getItems({ page: 0, pageSize: 6, status: 'usable' }),
      memberApi.getRedeemable({ page: 0, pageSize: 10 }),
    ])
      .then(async ([meRes, missionsRes, itemsRes, redeemablesRes]) => {
        me = meRes;
        missions = missionsRes;
        items = itemsRes;
        redeemables = redeemablesRes;
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: 'SET_DIALOG',
          payload: {
            open: true,
            message: error?.response?.data?.error?.message,
          },
        });
      });
    const state = {
      init: true,
      me: me,
      missions: missions,
      redeemables: redeemables,
      items: items,
    };
    dispatch({ type: 'INIT', payload: state });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'SET_DIALOG',
      payload: {
        open: true,
        message: error?.response?.data?.error?.message,
      },
    });
  }
}

async function changeLocale({ lang, timezone, dispatch }) {
  if (lang === 'en') {
    localStorage.setItem('i18nextLng', 'en-US');
    i18n.changeLanguage('en-US');
    memberApi.setLanguage('en');
    dispatch({ type: 'SWITCH_LANGUAGE', payload: 'en' });
  }
  if (lang === 'zh') {
    localStorage.setItem('i18nextLng', 'zh-HK');
    i18n.changeLanguage('zh-HK');
    memberApi.setLanguage('zh');
    dispatch({ type: 'SWITCH_LANGUAGE', payload: 'zh' });
  }
  try {
    await memberApi.patchPreference({ lang: lang, timezone: timezone });
  } catch (error) {}
}

export { initApp, reload, changeLocale };
