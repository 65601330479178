import { Card, CardMedia, makeStyles } from '@material-ui/core';
import { formatDate } from '../../libs/date';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CommonAppBar from '../../components/CommonAppBar';
import CommonDescription from '../../components/CommonDescription';
import CommonFooter from '../../components/CommonFooter';
import React from 'react';
import colors from '../../style/colors';
import useStore from '../../hooks/useStore';

const useStyle = makeStyles({
  container: {
    margin: 24,
    paddingBottom: 48,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.branding,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grey50,
  },
  titleContainer: {
    textAlign: 'center',
    marginBottom: 24,
    marginTop: 74,
  },
  image: {
    minHeight: 171,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
    marginBottom: 24,
  },
  imageContent: {
    objectFit: 'cover',
    minHeight: 171,
    backgroundColor: colors.grey,
  },
});

export default function PromotionDetail() {
  const classes = useStyle();
  const location = useLocation();
  const { lang } = useStore();
  const { t } = useTranslation();

  const data = location.state?.data;

  const getSubtitle = () => {
    if (data?.publish_at && data?.expire_at) {
      return `${formatDate(data?.publish_at, lang)} - ${formatDate(
        data?.expire_at,
        lang,
      )}`;
    } else if (data?.publish_at) {
      return t('promotion.startAfter', {
        date: formatDate(data?.publish_at, lang),
      });
    } else if (data?.expire_at) {
      return t('promotion.endBefore', {
        date: formatDate(data?.expire_at, lang),
      });
    }
  };

  return (
    <>
      <CommonAppBar backButton />
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{data?.title[lang]}</span>
          <br />
          <span className={classes.subtitle}>{getSubtitle()}</span>
        </div>
        <Card className={classes.image}>
          {data?.image[lang]?.url && (
            <CardMedia
              className={classes.imageContent}
              component="img"
              image={data?.image[lang]?.url}
              alt="image"
            />
          )}
        </Card>
        <CommonDescription data={data.details[lang]} />
        <CommonFooter />
      </div>
    </>
  );
}
