import { Card, CardMedia, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PointIcon from '../../../../assets/icons/PointIcon';
import colors from '../../../../style/colors';
import useStore from '../../../../hooks/useStore';
import * as React from 'react';

const useStyles = makeStyles({
  itemContainer: {
    minWidth: 270,
    minHeight: 80,
    display: 'flex',
    marginBottom: 24,
    boxShadow: 'none',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  infoContainer: {
    marginLeft: 16,
    textAlign: 'left',
  },
  info: {
    lineHeight: 2,
    fontSize: 12,
    color: colors.branding,
    fontWeight: 500,
  },
  image: {
    minWidth: 88,
    minHeight: 88,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
  },
  imageContent: {
    objectFit: 'cover',
    width: 88,
    height: 88,
    backgroundColor: colors.grey,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  pointIcon: {
    fontSize: 16,
    marginRight: 5,
    color: colors.branding,
  },
  pointContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default function RewardItem(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { lang } = useStore();
  const navigate = useNavigate();

  return (
    <Card
      className={classes.itemContainer}
      onClick={() => {
        navigate('/reward', {
          state: { data: data, type: 'redeemable' },
        });
      }}
    >
      <Card className={classes.image}>
        {data?.image[lang]?.url && (
          <CardMedia
            className={classes.imageContent}
            component="img"
            image={data?.image[lang]?.url}
            alt="image"
          />
        )}
      </Card>
      <div className={classes.infoContainer}>
        <span className={classes.title}>{data?.title[lang]}</span>
        <br />
        <div className={classes.pointContainer}>
          <PointIcon className={classes.pointIcon} />
          <span className={classes.info}>
            {t('home.pointNeeded', {
              point: data?.cost?.point?.amount,
              pointTitle: data?.cost?.point?.title[lang],
            })}
          </span>
        </div>
      </div>
    </Card>
  );
}
