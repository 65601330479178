import axios from 'axios';

import config from '../../config';

export class MemberApi {
  /** @type {AxiosInstance} */
  axios;
  /** @type {string} */
  refreshToken;

  constructor(url) {
    this.axios = axios.create({
      baseURL: url || config.api,
    });
  }

  setToken = (auth) => {
    this.axios.defaults.headers.common.Authorization = `${auth.token_type} ${
      auth.access_token
    }`;
    this.refreshToken = auth.refresh_token;
  };

  setLanguage = (lang) => {
    this.axios.defaults.headers.common['Accept-Language'] = lang;
  };

  async login(username, password) {
    const endpoint = '../../oauth2/token';
    const clientId = 'twtvvpr7xe9zj6h7hilpqsqle';

    const bodyParams = new URLSearchParams();
    bodyParams.append('grant_type', 'password');
    bodyParams.append('client_id', clientId);
    bodyParams.append('username', username);
    bodyParams.append('password', password);

    const response = await this.axios.post(endpoint, bodyParams);
    this.setToken(response.data);
    return response.data;
  }

  async getToken(token) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const endpoint = '../../oauth2/tokeninfo';

    const response = await this.axios.get(endpoint);
    this.setToken(response.data);
    return response.data;
  }

  async refresh({ clientId }) {
    const endpoint = '../../oauth2/token';

    const bodyParams = new URLSearchParams();
    bodyParams.append('grant_type', 'refresh_token');
    bodyParams.append('client_id', clientId);
    bodyParams.append('refresh_token', this.refreshToken);

    const response = await this.axios.post(endpoint, bodyParams);
    this.setToken(response.data);
    return response.data;
  }

  async walletLogin({ hashToken, clientId }) {
    const endpoint = '../../oauth2/token';

    const bodyParams = new URLSearchParams();
    bodyParams.append('grant_type', 'wallet_hash_token');
    bodyParams.append('client_id', clientId);
    bodyParams.append('hash_token', hashToken);

    const response = await this.axios.post(endpoint, bodyParams);
    this.setToken(response.data);
    return response.data;
  }

  async getProgram() {
    const response = await this.axios.get('/program');

    return response.data;
  }

  async getMe() {
    const response = await this.axios.get('/me');

    return response.data;
  }

  async getMissions({ page, pageSize }) {
    const endpoint = '/me/missions';

    const query = {
      page: page,
      page_size: pageSize,
    };

    const response = await this.axios.get(endpoint, { params: query });
    return response.data;
  }

  async getItems({ page, pageSize, status }) {
    const endpoint = '/me/items';

    const query = {
      page: page,
      page_size: pageSize,
      status: status,
    };

    const response = await this.axios.get(endpoint, { params: query });
    return response.data;
  }

  async getRedeemable({ page, pageSize }) {
    const endpoint = '/redeemables';

    const query = {
      page: page,
      page_size: pageSize,
    };

    const response = await this.axios.get(endpoint, { params: query });
    return response.data;
  }

  async redeemItem({ id, costId }) {
    const endpoint = `/redeemables/${id}/redeem`;

    const response = await this.axios.post(endpoint, { cost_id: costId });
    return response.data;
  }

  async getPointHistory({ page, pageSize, schemeId }) {
    const endpoint = `/me/points`;

    const query = {
      page: page,
      page_size: pageSize,
      scheme_id: schemeId,
    };

    const response = await this.axios.get(endpoint, { params: query });
    return response.data;
  }

  async getPromotions({ page, pageSize }) {
    const endpoint = `/newses`;

    const query = {
      page: page,
      page_size: pageSize,
    };

    const response = await this.axios.get(endpoint, { params: query });
    return response.data;
  }

  async patchPreference({ lang, timezone }) {
    const endpoint = `/me/preference`;

    const response = await this.axios.patch(endpoint, {
      language: lang,
      timezone: timezone,
    });
    return response.data;
  }
}

const memberApi = new MemberApi();

export default memberApi;
