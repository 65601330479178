import { CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonFooter from '../../../../components/CommonFooter';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect, useState } from 'react';
import RewardIcon from '../../../../assets/icons/RewardIcon';
import RewardItem from '../RewardItem';
import colors from '../../../../style/colors';
import memberApi from '../../../../libs/api/memberApi';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: colors.branding,
  },
  titleContainer: {
    marginBottom: 16,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'flex-start',
  },
  rewardIcon: {
    marginRight: 8,
  },
  scrollContainer: {
    paddingBottom: 48,
  },
});

export default function RewardList(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { init, dispatch } = useStore();

  const pageSize = 10;

  const checkPageEnd = (data) => {
    if (data.length < pageSize) {
      setHasMore(false);
    } else {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    }
  };

  const fetchData = async () => {
    try {
      const response = await memberApi.getRedeemable({
        page: currentPage,
        pageSize: pageSize,
      });
      setRewards((prevData) => [...prevData, ...response]);
      checkPageEnd(response);
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
  };

  useEffect(
    () => {
      if (init) {
        setRewards(data);
        if (data?.length < pageSize) {
          setHasMore(false);
        }
      }
    },
    [init],
  );

  return (
    <>
      <div className={classes.titleContainer}>
        <RewardIcon className={classes.rewardIcon} style={{ fontSize: 14 }} />
        <span className={classes.title}>{t('home.browseRewards')}</span>
      </div>
      <InfiniteScroll
        className={classes.scrollContainer}
        dataLength={rewards.length}
        next={() => {
          if (rewards.length > 0) {
            fetchData();
          }
        }}
        hasMore={hasMore}
        loader={
          <div className={classes.loaderContainer}>
            <CircularProgress size={30} className={classes.loader} />
          </div>
        }
      >
        {rewards?.map((reward) => {
          return <RewardItem data={reward} key={reward?.id} />;
        })}
        {!hasMore && <CommonFooter />}
      </InfiniteScroll>
    </>
  );
}
