import { CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonActiveButton from '../../components/CommonActiveButton';
import CommonAppBar from '../../components/CommonAppBar';
import CommonFooter from '../../components/CommonFooter';
import CommonPageTitle from '../../components/CommonPageTitle';
import InfiniteScroll from 'react-infinite-scroll-component';
import MemberCard from './component/MemberCard';
import PointItem from './component/PointItem';
import React, { useEffect, useState } from 'react';
import RewardItem from './component/RewardItem';
import colors from '../../style/colors';
import memberApi from '../../libs/api/memberApi';
import useStore from '../../hooks/useStore';

const useStyle = makeStyles({
  container: {
    margin: 24,
  },
  loaderContainer: {
    textAlign: 'center',
    margin: 16,
  },
  loader: {
    color: colors.grey50,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  scrollContainer: {
    paddingBottom: 48,
  },
  inboxIcon: {
    color: colors.text.light,
  },
  emptyMessage: {
    fontSize: 16,
    color: colors.disable,
    fontWeight: 500,
    margin: 16,
  },
});

function determineDefaultScheme(program) {
  if (program?.default_point_scheme) {
    return 'point';
  } else if (program?.credit_point_scheme) {
    return 'credit';
  } else {
    return 'item';
  }
}

export default function TransactionHistory() {
  const { program, me, tierStyle, init, lang, dispatch } = useStore();

  const classes = useStyle();
  const { t } = useTranslation();
  const [scheme, setScheme] = useState(determineDefaultScheme(program));
  const [data, setData] = useState([]);
  const [unusableItemData, setUnusableItemData] = useState([]);
  const [expiredItemData, setExpiredItemData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [unusableCurrentPage, setUnusableCurrentPage] = useState(0);
  const [expiredCurrentPage, setExpiredCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [unusableHasMoreData, setUnusableHasMoreData] = useState(true);
  const [expiredHasMoreData, setExpiredHasMoreData] = useState(true);

  const pageSize = 10;

  const checkPageEnd = (data) => {
    if (data.length < pageSize) {
      setHasMoreData(false);
    } else {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    }
  };

  const getPoint = async () => {
    try {
      const response = await memberApi.getPointHistory({
        page: currentPage,
        pageSize: pageSize,
        schemeId: program?.default_point_scheme?.id,
      });
      setData((prevData) => [...prevData, ...response]);
      checkPageEnd(response);
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
  };

  const getCredit = async () => {
    try {
      const response = await memberApi.getPointHistory({
        page: currentPage,
        pageSize: pageSize,
        schemeId: program?.credit_point_scheme?.id,
      });
      setData((prevData) => [...prevData, ...response]);
      checkPageEnd(response);
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
  };

  const getItem = async () => {
    try {
      let unusableResponse = [];
      let expiredResponse = [];
      if (unusableHasMoreData) {
        unusableResponse = await memberApi.getItems({
          page: unusableCurrentPage,
          pageSize: pageSize,
          status: 'unusable',
        });
        setUnusableItemData((prevData) => [...prevData, ...unusableResponse]);
        if (unusableResponse.length < pageSize) {
          setUnusableHasMoreData(false);
        } else {
          setUnusableCurrentPage(unusableCurrentPage + 1);
        }
      }
      if (expiredHasMoreData) {
        expiredResponse = await memberApi.getItems({
          page: expiredCurrentPage,
          pageSize: pageSize,
          status: 'expired',
        });
      }
      setExpiredItemData((prevData) => [...prevData, ...expiredResponse]);
      if (expiredResponse.length < pageSize) {
        setExpiredHasMoreData(false);
      } else {
        setExpiredCurrentPage(expiredCurrentPage + 1);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
  };

  const fetchData = () => {
    switch (scheme) {
      case 'point': {
        getPoint();
        break;
      }
      case 'credit': {
        getCredit();
        break;
      }
      case 'item': {
        getItem();
        break;
      }
      default:
        break;
    }
  };

  const reloadData = () => {
    setData([]);
    setExpiredItemData([]);
    setUnusableItemData([]);
    setCurrentPage(0);
    setUnusableCurrentPage(0);
    setExpiredCurrentPage(0);
    setHasMoreData(true);
    setUnusableHasMoreData(true);
    setExpiredHasMoreData(true);
  };

  useEffect(
    () => {
      if (init) {
        fetchData();
      }
    },
    [init, scheme],
  );

  return (
    <>
      <CommonAppBar />
      <div className={classes.container}>
        <CommonPageTitle title={t('history.title')} />
        <MemberCard data={me} tierStyle={tierStyle} />
        <div className={classes.row}>
          { program?.default_point_scheme && (
            <CommonActiveButton
              active={scheme === 'point'}
              text={program?.default_point_scheme?.title[lang]}
              onClick={async () => {
                if (scheme !== 'point') {
                  setScheme('point');
                  reloadData();
                }
              }}
              margin="0px 8px"
            />
          )}
          { program?.credit_point_scheme && (
            <CommonActiveButton
              active={scheme === 'credit'}
              text={program?.credit_point_scheme?.title[lang]}
              onClick={async () => {
                if (scheme !== 'credit') {
                  setScheme('credit');
                  reloadData();
                }
              }}
              margin="0px 8px"
            />
          )}
          <CommonActiveButton
            active={scheme === 'item'}
            text={t('history.rewards')}
            onClick={async () => {
              if (scheme !== 'item') {
                setScheme('item');
                reloadData();
              }
            }}
            margin="0px 8px"
          />
        </div>
        {scheme === 'item' &&
          unusableItemData.length + expiredItemData.length === 0 && (
            <div className={classes.centerContainer}>
              <span className={classes.emptyMessage}>{t('history.empty')}</span>
            </div>
          )}
        {scheme !== 'item' && data?.length === 0 && (
          <div className={classes.centerContainer}>
            <span className={classes.emptyMessage}>{t('history.empty')}</span>
          </div>
        )}
        <InfiniteScroll
          className={classes.scrollContainer}
          dataLength={
            scheme === 'item'
              ? unusableItemData.length + expiredItemData.length
              : data.length
          }
          next={() => {
            if (
              scheme === 'item' &&
              unusableItemData.length + expiredItemData.length > 0
            ) {
              fetchData();
            } else if (data.length > 0) {
              fetchData();
            }
          }}
          hasMore={
            scheme === 'item'
              ? unusableHasMoreData || expiredHasMoreData
              : hasMoreData
          }
          loader={
            <div className={classes.loaderContainer}>
              <CircularProgress size={30} className={classes.loader} />
            </div>
          }
          endMessage={<CommonFooter />}
        >
          {scheme !== 'item' && (
            <>
              {[...new Set(data)]?.map((item) => (
                <PointItem data={item} key={item.id} />
              ))}
            </>
          )}
          {scheme === 'item' && (
            <>
              {[...new Set(unusableItemData)].map((item) => (
                <RewardItem data={item} key={item.id} />
              ))}
              {[...new Set(expiredItemData)].map((item) => (
                <RewardItem data={item} key={item.id} />
              ))}
            </>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
}
