import { CircularProgress, makeStyles } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import CommonFooter from '../../../../components/CommonFooter';
import InfiniteScroll from 'react-infinite-scroll-component';
import MissionCard from '../MissionCard';
import React, { useEffect, useState } from 'react';
import memberApi from '../../../../libs/api/memberApi';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  skeleton: {
    marginBottom: 24,
  },
  scrollContainer: {
    paddingBottom: 48,
  },
});

export default function MissionList(props) {
  const { data, isLoading } = props;
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(true);
  const [missions, setMissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { init, dispatch } = useStore();

  const pageSize = 5;

  const checkPageEnd = (data) => {
    if (data.length < pageSize) {
      setHasMore(false);
    } else {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    }
  };

  const fetchData = async () => {
    try {
      const response = await memberApi.getMissions({
        page: currentPage,
        pageSize: pageSize,
      });
      setMissions((prevData) => [...prevData, ...response]);
      checkPageEnd(response);
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
  };

  useEffect(
    () => {
      if (init) {
        setMissions(data);
        if (data?.length < pageSize) {
          setHasMore(false);
        }
      }
    },
    [init],
  );

  return (
    <>
      {!isLoading && (
        <InfiniteScroll
          className={classes.scrollContainer}
          dataLength={missions.length}
          next={() => {
            if (missions.length > 0) {
              fetchData();
            }
          }}
          hasMore={hasMore}
          loader={
            <div className={classes.loaderContainer}>
              <CircularProgress size={30} className={classes.loader} />
            </div>
          }
        >
          {missions?.map((mission) => {
            return <MissionCard data={mission} key={mission?.id} />;
          })}
          {!hasMore && <CommonFooter />}
        </InfiniteScroll>
      )}
      {isLoading && (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={340}
            className={classes.skeleton}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={340}
            className={classes.skeleton}
          />
        </>
      )}
    </>
  );
}
