import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CommonAppBar from '../../components/CommonAppBar';
import CommonDescription from '../../components/CommonDescription';
import CommonFooter from '../../components/CommonFooter';
import CommonHeaderImage from '../../components/CommonHeaderImage';
import CommonPageTitle from '../../components/CommonPageTitle';
import React from 'react';
import StampCard from './components/StampCard';
import useStore from '../../hooks/useStore';

const useStyles = makeStyles({
  container: {
    margin: 24,
    paddingBottom: 48,
  },
});

export default function MissionDetail(props) {
  const classes = useStyles();
  const location = useLocation();
  const { lang } = useStore();
  const { t } = useTranslation();

  const data = location.state?.data;

  return (
    <>
      <CommonAppBar backButton />
      <div className={classes.container}>
        <CommonPageTitle title={data?.title[lang]} largeTitle />
        <CommonHeaderImage image={data?.image[lang]} />
        <StampCard data={data} />
        <CommonDescription data={data?.details[lang]} />
        <CommonFooter />
      </div>
    </>
  );
}
