const tunglanConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyBcxI96lWrWLlnlMlZOcQdm3UB70rkWMnc",
    authDomain: "tunglan-8556e.firebaseapp.com",
    projectId: "tunglan-8556e",
    storageBucket: "tunglan-8556e.appspot.com",
    messagingSenderId: "668648410683",
    appId: "1:668648410683:web:045f78ee4b44c22d80561e",
    measurementId: "G-JBNVLM66LW",
  },
};

export default tunglanConfig;
