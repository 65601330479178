import { Dialog, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../components/CommonButton';
import React from 'react';
import colors from '../../style/colors';
import useStore from '../../hooks/useStore';

const useStyle = makeStyles({
  dialogContainer: {
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  dialogMessage: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.branding,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 16,
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: colors.branding,
    textAlign: 'center',
  },
});

export default function ErrorDialog() {
  const classes = useStyle();
  const { dialog, dispatch } = useStore();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch({
      type: 'SET_DIALOG',
      payload: {
        open: false,
        title: null,
        message: null,
      },
    });
  };

  function PaperComponent() {
    return (
      <div className={classes.dialogContainer}>
        {dialog?.title && (
          <span className={classes.dialogTitle}>{dialog?.title}</span>
        )}
        <span className={classes.dialogMessage}>{dialog?.message}</span>
        <br />
        <CommonButton
          onClick={handleClose}
          text={t('reward.done')}
          minWidth={150}
          minHeight={20}
          textStyle={{ fontSize: 12 }}
          padding={'10px'}
        />
      </div>
    );
  }

  return (
    <Dialog
      onClose={handleClose}
      open={dialog?.open ?? false}
      PaperComponent={PaperComponent}
    />
  );
}
