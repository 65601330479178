import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { changeLocale } from '../../libs/libs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackButton from '../../assets/icons/BackButton';
import CloseButton from '../../assets/icons/CloseButton';
import MenuButton from '../../assets/icons/menu';
import React, { useState } from 'react';
import colors from '../../style/colors';
import useStore from '../../hooks/useStore';

const useStyles = makeStyles({
  drawer: {
    width: '80%',
  },
  drawerContent: {
    paddingTop: 64,
    paddingLeft: 32,
    paddingRight: 32,
  },
  container: {
    boxShadow: 'none',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 24px',
  },
  icon: {
    height: 16,
    width: 16,
  },
  centerLogo: {
    textAlign: 'center',
    height: 24,
  },
  backButton: {
    width: 34,
    height: 34,
  },
  langButton: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    border: `1px solid ${colors.button.light}`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  langText: {
    fontSize: 14,
    color: colors.branding,
  },
  menuTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 40,
    paddingRight: 32,
    paddingLeft: 32,
  },
  menuTitle: {
    color: colors.branding,
    fontWeight: 700,
    fontSize: 16,
  },
  divider: {
    marginTop: 16,
    marginLeft: 32,
    marginRight: 32,
  },
  menuList: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
  },
  menuText: {
    color: colors.branding,
    fontWeight: 600,
    fontSize: 14,
  },
});

export default function CommonAppBar(props) {
  const { backButton = false } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, lang, me } = useStore();
  const program = JSON.parse(localStorage.getItem('program'));

  return (
    <AppBar position="fixed" className={classes.container}>
      <Toolbar className={classes.content}>
        {!backButton && (
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}
            style={{ marginRight: 18 }}
          >
            <MenuButton className={classes.icon} />
          </IconButton>
        )}
        {backButton && (
          <IconButton
            edge="start"
            aria-label="lang"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackButton className={classes.backButton} />
          </IconButton>
        )}
        {program && (
          <img
            className={classes.centerLogo}
            alt="center-logo"
            src={program?.logo?.url}
            onClick={() => {
              navigate('/');
            }}
          />
        )}
        <IconButton
          edge="end"
          aria-label="lang"
          onClick={async () => {
            if (lang === 'zh') {
              await changeLocale({
                lang: 'en',
                timezone: me?.preferences?.timezone,
                dispatch: dispatch,
              });
            }
            if (lang === 'en') {
              await changeLocale({
                lang: 'zh',
                timezone: me?.preferences?.timezone,
                dispatch: dispatch,
              });
            }
          }}
        >
          <div className={classes.langButton}>
            <span className={classes.langText}>{t('common.lang')}</span>
          </div>
        </IconButton>
        <Drawer
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{ className: classes.drawer }}
        >
          <div className={classes.menuTop}>
            <span className={classes.menuTitle}>{t('sideMenu.menu')}</span>
            <IconButton
              edge="end"
              aria-label="closeMenu"
              onClick={() => setIsDrawerOpen(false)}
            >
              <CloseButton className={classes.icon} />
            </IconButton>
          </div>
          <Divider className={classes.divider} />
          <List className={classes.menuList}>
            <ListItem
              button
              onClick={() => {
                setIsDrawerOpen(false);
                if (location.pathname !== '/') {
                  setTimeout(() => {
                    navigate('/');
                  }, 200);
                }
              }}
            >
              <ListItemText
                primaryTypographyProps={{ className: classes.menuText }}
                primary={t('sideMenu.cardEnjoyment')}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setIsDrawerOpen(false);
                if (location.pathname !== '/transaction') {
                  setTimeout(() => {
                    navigate('/transaction');
                  }, 200);
                }
              }}
            >
              <ListItemText
                primaryTypographyProps={{ className: classes.menuText }}
                primary={t('sideMenu.transactionHistory')}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setIsDrawerOpen(false);
                if (location.pathname !== '/promotionList') {
                  setTimeout(() => {
                    navigate('/promotionList');
                  }, 200);
                }
              }}
            >
              <ListItemText
                primaryTypographyProps={{ className: classes.menuText }}
                primary={t('sideMenu.promotion')}
              />
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
