const defaultConfig = {
  firebaseConfig: {
    apiKey: 'AIzaSyD6S0DqjwS_5rXh7ICfZ98b4cPXE1sGPFs',
    authDomain: 'platform-member-app.firebaseapp.com',
    projectId: 'platform-member-app',
    storageBucket: 'platform-member-app.appspot.com',
    messagingSenderId: '347541541603',
    appId: '1:347541541603:web:79e2a6bc9dd69f27f86bd6',
    measurementId: 'G-02EM636KP0',
  },
  vapidKey:
    'BAMZWvn0iyCkIfRe3x2PYnOZQKVfZI6OBSgHfFJ3fgQe6_Bbs3NhVhtF3hopGR0gMRbBFvhhnGAh5hiVCKMtnog',
};

export default defaultConfig;
