// @flow
import { Dialog, Slide, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/CommonButton';
import Gift from '../../../../assets/images/Gift';
import React from 'react';
import colors from '../../../../style/colors';

const useStyle = makeStyles({
  backdrop: {
    backgroundColor: colors.backdrop,
  },
  modalContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 16,
    textAlign: 'center',
  },
  giftImage: {
    width: 110,
    height: 112,
    marginBottom: 48,
  },
  successTitleTop: {
    fontSize: 24,
    fontWeight: 600,
    color: colors.white,
  },
  successTitleCenter: {
    fontSize: 50,
    fontWeight: 600,
    color: colors.white,
    padding: '0px 24px 24px 24px',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessfulDialog(props) {
  const classes = useStyle();
  const { onActionButtonClick, open, onClose } = props;
  const { t } = useTranslation();

  function PaperComponent() {
    return (
      <div className={classes.modalContainer}>
        <span className={classes.successTitleTop}>
          {t('reward.successTitle')}
        </span>
        <br />
        <span className={classes.successTitleCenter}>{t('reward.thanks')}</span>
        <Gift className={classes.giftImage} />
        <CommonButton
          onClick={onActionButtonClick}
          text={t('reward.done')}
          backgroundColor={colors.white}
          textColor={colors.branding}
        />
      </div>
    );
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      PaperComponent={PaperComponent}
      className={classes.backdrop}
      open={open}
      onClose={onClose}
    />
  );
}
