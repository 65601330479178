import { Card, CardMedia, Divider, makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import { formatDate } from '../../../../libs/date';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import React from 'react';
import StampIcon from '../../../../assets/icons/StampIcon';
import colors from '../../../../style/colors';
import styled from '@emotion/styled';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  card: {
    border: '1px solid',
    borderColor: colors.grey10,
    boxShadow: 'none',
    padding: 12,
    marginBottom: 24,
    textAlign: 'left',
  },
  image: {
    minWidth: 88,
    height: 88,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
  },
  imageContent: {
    objectFit: 'cover',
    width: 88,
    height: 88,
    backgroundColor: colors.grey,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  detailContainer: {
    marginLeft: 16,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.text.light,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  info: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.branding,
  },
  stampIcon: {
    fontSize: 14,
    color: colors.branding,
    marginRight: 5,
  },
  infoHighlighted: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  buttonText: {
    fontSize: 10,
    color: colors.branding,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  divider: {
    margin: '16px 0px',
    backgroundColor: colors.grey10,
  },
  stamp: {
    height: 45,
    width: 45,
  },
  gridItem: {
    textAlign: 'center',
  },
  grid: {
    textAlign: 'center',
  },
  viewButton: {
    marginTop: 16,
  },
});

export default function MissionCard(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useStore();

  const totalStamp = data?.progress?.target;
  const currentStamp = data?.progress?.current;

  const getStampRowNumber = () => {
    let i = 1;
    while (totalStamp / i > 5) {
      i++;
    }
    return i;
  };

  const getStampColumnNumber = () => {
    const row = getStampRowNumber();
    return Math.ceil(totalStamp / row);
  };

  const Stamp = () => {
    return <img className={classes.stamp} alt="stamp" src={data?.stamp?.url} />;
  };

  const StampEmpty = () => {
    return (
      <img
        className={classes.stamp}
        alt="stamp_empty"
        src={data?.stamp_placeholder?.url}
      />
    );
  };

  const stampColumnNumber = getStampColumnNumber();

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 15,
    borderRadius: 10,
    marginTop: 32,
    marginBottom: 24,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.progressBar,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors.branding,
    },
  }));

  return (
    <Card
      className={classes.card}
      onClick={() => {
        navigate('/mission', {
          state: { data: data },
        });
      }}
    >
      <div className={classes.row}>
        <Card className={classes.image}>
          {data?.image[lang]?.url && (
            <CardMedia
              className={classes.imageContent}
              component="img"
              image={data?.image[lang]?.url}
              alt="image"
            />
          )}
        </Card>
        <div className={classes.detailContainer}>
          {data?.expire_at && (
            <>
              <span className={classes.subtitle}>
                {t('home.endsOn', { date: formatDate(data?.expire_at, lang) })}
              </span>
              <br />
            </>
          )}
          <span className={classes.title}>{data?.title[lang]}</span>
          <br />
          <span>
            <StampIcon className={classes.stampIcon} />
            <span className={classes.infoHighlighted}>{currentStamp}</span>
            <span className={classes.info}>
              {' '}
              / {totalStamp} {t('home.stamps')}
            </span>
          </span>
        </div>
      </div>
      <Divider className={classes.divider} />
      {data?.display_type !== 'progress' && (
        <Grid
          className={classes.grid}
          container
          columns={stampColumnNumber}
          rowSpacing={2}
        >
          {[...Array(currentStamp ?? 0)].map((_, index) => (
            <Grid className={classes.gridItem} item xs={1} key={index}>
              <Stamp />
            </Grid>
          ))}
          {[...Array(totalStamp - (currentStamp ?? 0))].map((_, index) => (
            <Grid className={classes.gridItem} item xs={1} key={index}>
              <StampEmpty />
            </Grid>
          ))}
        </Grid>
      )}
      {data?.display_type === 'progress' && (
        <BorderLinearProgress
          variant="determinate"
          value={(currentStamp / totalStamp) * 100}
        />
      )}
      <div className={classes.viewButton}>
        <span className={classes.buttonText}>{t('home.viewMore')}</span>
      </div>
      {/* </Button> */}
    </Card>
  );
}
