// @flow
import { makeStyles } from '@material-ui/core';
import HTMLString from 'react-html-string';
import React from 'react';
import colors from '../../style/colors';

const useStyles = makeStyles({
  container: {
    marginBottom: 32,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  header: {
    marginBottom: 8,
  },
});

export default function CommonDescription(props) {
  const { data, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {title && (
        <div className={classes.header}>
          <span className={classes.title}>{title}</span>
        </div>
      )}
      {data && <HTMLString html={data} />}
    </div>
  );
}
