import { Card, CardMedia, makeStyles } from '@material-ui/core';
import React from 'react';
import Ribbon from '../../assets/images/Ribbon';
import colors from '../../style/colors';

const useStyles = makeStyles({
  imageHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: 116,
    marginBottom: 32,
  },
  ribbon: {
    width: 260,
    height: 59,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 100,
    minHeight: 100,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
  },
  imageContent: {
    objectFit: 'cover',
    width: 100,
    height: 100,
    backgroundColor: colors.grey,
  },
});

export default function CommonHeaderImage(props) {
  const classes = useStyles();
  const { image } = props;

  return (
    <div className={classes.imageHeader}>
      <Ribbon className={classes.ribbon} />
      <Card className={classes.image}>
        {image?.url && (
          <CardMedia
            className={classes.imageContent}
            component="img"
            image={image?.url}
            alt="image"
          />
        )}
      </Card>
    </div>
  );
}
