import { Card, CardMedia, makeStyles } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../libs/date';
import colors from '../../../style/colors';
import useStore from '../../../hooks/useStore';
import * as React from 'react';

const useStyles = makeStyles({
  itemContainer: {
    minWidth: 270,
    minHeight: 80,
    display: 'flex',
    marginRight: 24,
    boxShadow: 'none',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: 24,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  infoContainer: {
    marginLeft: 16,
  },
  info: {
    fontSize: 12,
    color: colors.grey50,
    fontWeight: 500,
  },
  textButton: {
    padding: '5px 0px',
    alignItems: 'center',
    display: 'flex',
  },
  buttonText: {
    fontSize: 10,
    color: colors.branding,
    fontWeight: 700,
    textDecoration: 'underline',
  },
  image: {
    minWidth: 88,
    minHeight: 88,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
  },
  imageContent: {
    objectFit: 'cover',
    width: 88,
    height: 88,
    backgroundColor: colors.grey,
  },
  greenDot: {
    backgroundColor: colors.success,
    minHeight: 8,
    minWidth: 8,
    borderRadius: 4,
    marginRight: 8,
    marginTop: 6,
  },
  row: {
    display: 'flex',
    alignItems: 'start',
  },
});

export default function WalletItem(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { lang } = useStore();
  const navigate = useNavigate();

  return (
    <Card
      className={classes.itemContainer}
      onClick={() => {
        navigate('/reward', {
          state: { data: data, type: 'item' },
        });
      }}
    >
      <Card className={classes.image}>
        {data?.image[lang]?.url && (
          <CardMedia
            className={classes.imageContent}
            component="img"
            image={data?.image[lang]?.url}
            alt="image"
          />
        )}
      </Card>
      <div className={classes.infoContainer}>
        <div className={classes.row}>
          <div className={classes.greenDot} />
          <div className={classes.title}>{data?.title[lang]}</div>
        </div>
        {data?.expire_at && (
          <span className={classes.info}>
            {t('home.expiryDate', { date: formatDate(data?.expire_at, lang) })}
          </span>
        )}
        <div className={classes.textButton}>
          <span className={classes.buttonText}>{t('home.useNow')}</span>
        </div>
      </div>
    </Card>
  );
}
