import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { WrapContext } from './store';
import Main from './pages/Main';
import history from './libs/history';
import theme from './theme/theme';
import './assets/fonts/Montserrat-Regular.ttf';
import './assets/fonts/Montserrat-SemiBold.ttf';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <WrapContext>
          <Main />
        </WrapContext>
      </Router>
    </ThemeProvider>
  );
}

export default App;
