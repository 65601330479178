import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import colors from '../../style/colors';
import styled from '@emotion/styled';

const useStyles = makeStyles({
  text: {
    fontSize: 12,
    fontWeight: 500,
  },
  container: {
    display: 'flex',
    color: colors.white,
    boxShadow: 'none',
    minWidth: 84,
    borderRadius: 50,
    border: '1px solid',
    borderColor: colors.branding,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 28,
  },
});

export default function CommonActiveButton(props) {
  const { onClick, active, text, margin, padding } = props;
  const classes = useStyles();

  const ActiveButton = styled(Button)(() => ({
    color: colors.white,
    boxShadow: 'none',
    backgroundColor: active ? colors.branding : colors.transparent,
    padding: padding ?? '0px 16px',
    minWidth: 84,
    borderRadius: 50,
    border: '1px solid',
    borderColor: colors.branding,
    margin: margin,
    height: 28,
    '&:hover': {
      backgroundColor: colors.transparent,
      borderColor: colors.branding,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: colors.transparent,
      borderColor: colors.branding,
    },
  }));

  return (
    <div
      className={classes.container}
      onClick={onClick}
      style={{
        backgroundColor: active ? colors.branding : colors.transparent,
        padding: padding ?? '0px 16px',
        margin: margin,
      }}
    >
      <span
        className={classes.text}
        style={{ color: active ? colors.white : colors.branding }}
      >
        {text}
      </span>
    </div>
  );
}
