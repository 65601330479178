// @flow
import { Divider, makeStyles } from '@material-ui/core';
import { formatDate } from '../../../../libs/date';
import React from 'react';
import colors from '../../../../style/colors';
import useStore from '../../../../hooks/useStore';

const useStyle = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
  },
  title: {
    fontSize: 14,
    color: colors.branding,
    fontWeight: 600,
    marginBottom: 6,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grey50,
  },
  info: {
    fontSize: 14,
    fontWeight: 700,
    flex: 1,
    textAlign: 'right',
  },
  divider: {
    backgroundColor: colors.grey10,
    marginBottom: 16,
  },
});

export default function PointItem(props) {
  const { data } = props;
  const classes = useStyle();
  const { lang } = useStore();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{data?.source[lang]}</span>
          <span className={classes.subTitle}>
            {formatDate(data?.created_at, lang)}
          </span>
        </div>
        <span
          className={classes.info}
          style={{ color: data?.amount > 0 ? colors.branding : colors.deduct }}
        >
          {data?.amount > 0 ? '+' : '-'} {Math.abs(data?.amount)}{' '}
          {data?.title[lang]}
        </span>
      </div>
      <Divider className={classes.divider} />
    </>
  );
}
