import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../assets/images/ErrorIcon';
import React from 'react';
import colors from '../../style/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  errorPage: {
    width: 123,
    height: 147,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: colors.branding,
  },
  content: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.branding,
  },
});

export default function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <ErrorIcon className={classes.errorPage} />
      <br />
      <span className={classes.title}>{t('accessError.title')}</span>
      <span className={classes.content}>{t('accessError.content')}</span>
    </div>
  );
}
