/* eslint-disable import/no-anonymous-default-export */
export default {
  primary: '#FFFFFF',
  secondary: '#F7F7F7',
  branding: '#4F4F4D',
  progressBar: '#4F4F4D33',
  text: {
    main: '#4F4F4D',
    secondary: '#4A4A4A',
    light: '#919191',
    dark: '#555657',
    highlight: '#D8B795',
  },
  button: {
    main: '#1C3AAE',
    light: '#EBEBEB',
  },
  black: '#000000',
  white: '#FFFFFF',
  deduct: '#CA0813',
  grey: '#E6E6E6',
  grey10: '#F1F1F1',
  grey50: '#727272',
  success: '#08CA33',
  error: '#f44336',
  disable: '#CECECE',
  warning: '#ff9800',
  backdrop: '#323232B3',
  overlay: '#00000066',
  transparent: 'transparent',
};
