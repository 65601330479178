import { createContext, useReducer } from 'react';

const ContextStore = createContext();

/** @type {IAppState} */
const initStore = {
  init: false,
  me: null,
  missions: null,
  redeemables: null,
  items: null,
  lang: 'zh',
  program: null,
  tierStyle: {},
  token: null,
  showMissionOrReward: 'mission',
  isLoading: false,
  dialog: {
    open: false,
    title: null,
    message: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return Object.assign({}, state, { ...action.payload });
    case 'SWITCH_LANGUAGE':
      return Object.assign({}, state, { lang: action.payload });
    case 'SET_LOADING':
      return Object.assign({}, state, { isLoading: action.payload });
    case 'SET_DIALOG':
      return Object.assign({}, state, { dialog: action.payload });
    case 'SET_SHOW_MISSION_OR_REWARD':
      return Object.assign({}, state, { showMissionOrReward: action.payload });
    default:
      return state;
  }
};

const WrapContext = (props) => {
  const [store, dispatch] = useReducer(reducer, initStore);

  return (
    <ContextStore.Provider
      value={{
        ...store,
        dispatch,
      }}
    >
      {props.children}
    </ContextStore.Provider>
  );
};

export default ContextStore;
export { WrapContext };
