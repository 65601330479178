const bitebybiteConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyBEpYAwbzv9NFPSF4LQM6w2f5z-7SVe158",
    authDomain: "sw-group-bite-by-bite.firebaseapp.com",
    projectId: "sw-group-bite-by-bite",
    storageBucket: "sw-group-bite-by-bite.appspot.com",
    messagingSenderId: "832558283374",
    appId: "1:832558283374:web:0531e634d2bb52fd7c447f",
    measurementId: "G-6LM99Q248X",
  },
};

export default bitebybiteConfig;
