import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import enUS from './locales/en-US.json';
import memberApi from '../libs/api/memberApi';
import zhHK from './locales/zh-HK.json';

let i18nextLng = localStorage.getItem('i18nextLng');
if (!i18nextLng) {
  i18nextLng = 'zh-HK';
  localStorage.setItem('i18nextLng', i18nextLng);
} else if (i18nextLng === 'zh-HK') {
  memberApi.setLanguage('zh');
} else if (i18nextLng === 'en-US') {
  memberApi.setLanguage('en');
}

const resources = {
  'en-US': {
    translation: enUS,
  },
  'zh-HK': {
    translation: zhHK,
  },
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: i18nextLng,
    fallbackLng: i18nextLng,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
