const nocConfig = {
  firebaseConfig: {
    apiKey: 'AIzaSyBRRmDjfEutBD8Iboomhc_YEi5pkO6F6B4',
    authDomain: 'noc-coffee-656d1.firebaseapp.com',
    projectId: 'noc-coffee-656d1',
    storageBucket: 'noc-coffee-656d1.appspot.com',
    messagingSenderId: '723205487573',
    appId: '1:723205487573:web:6479241465fb77d76edb5c',
    measurementId: 'G-M059ZYL9DV',
  },
  vapidKey:
    'BCZM_f5T0ubbEsYKYp6nuDeBwLvX3xV0zWpPFbflCRV5gaaaU8573R1c8ZrmXBbnF_x_xr-apQyU2_OS-eK6n60',
};

export default nocConfig;
