import { Button, IconButton, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MoreIcon from '../../../../assets/icons/MoreIcon';
import RightArrowIcon from '../../../../assets/icons/RightArrowIcon';
import WalletIcon from '../../../../assets/icons/WalletIcon';
import WalletItem from '../WalletItem';
import colors from '../../../../style/colors';
import * as React from 'react';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
  },
  scrollContainer: {
    overflowX: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: colors.branding,
  },
  listTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  titleContainer: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  walletIcon: {
    marginRight: 8,
  },
  moreButton: {
    marginRight: 12,
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  viewText: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.branding,
  },
  arrowIcon: {
    fontSize: 10,
    color: colors.branding,
    marginLeft: 5,
  },
  moreIcon: {
    height: 24,
    width: 24,
    marginBottom: 5,
  },
  moreText: {
    fontSize: 10,
    fontWeight: 600,
    color: colors.grey50,
  },
});

export default function WalletList(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.listTop}>
        <div className={classes.titleContainer}>
          <WalletIcon className={classes.walletIcon} style={{ fontSize: 16 }} />
          <span className={classes.title}>{t('home.myWallet')}</span>
        </div>
        {data?.length > 5 && (
          <Button
            onClick={() => {
              navigate('/wallet');
            }}
          >
            <span className={classes.viewText}>{t('home.viewAll')}</span>
            <RightArrowIcon className={classes.arrowIcon} />
          </Button>
        )}
      </div>
      <div className={classes.scrollContainer}>
        {data?.map((item, index) => {
          if (index < 5) {
            return <WalletItem data={item} key={item?.id} />;
          }
          return null;
        })}
        {data?.length > 5 && (
          <IconButton
            className={classes.moreButton}
            onClick={() => {
              navigate('/wallet');
            }}
          >
            <div className={classes.column}>
              <MoreIcon className={classes.moreIcon} />
              <span className={classes.moreText}>{t('home.more')}</span>
            </div>
          </IconButton>
        )}
      </div>
    </div>
  );
}
