import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import CommonAppBar from '../../components/CommonAppBar';
import CommonDescription from '../../components/CommonDescription';
import CommonFooter from '../../components/CommonFooter';
import CommonHeaderImage from '../../components/CommonHeaderImage';
import CommonPageTitle from '../../components/CommonPageTitle';
import InfoCard from './component/InfoCard';
import React from 'react';
import useStore from '../../hooks/useStore';

const useStyles = makeStyles({
  container: {
    margin: 24,
    paddingBottom: 48,
  },
});

export default function RewardDetail() {
  const classes = useStyles();
  const location = useLocation();
  const { lang } = useStore();

  const data = location.state?.data;
  const type = location.state?.type;

  return (
    <>
      <CommonAppBar backButton />
      <div className={classes.container}>
        <CommonPageTitle title={data?.title[lang]} largeTitle />
        {data?.image?.en && <CommonHeaderImage image={data?.image[lang]} />}
        {data?.details && <CommonDescription data={data?.details[lang]} />}
        <InfoCard data={data} type={type} />
        <CommonFooter />
      </div>
    </>
  );
}
