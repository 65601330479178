function formatDate(dateString, lang) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(dateString);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  if (lang === 'en') {
    return `${day} ${month} ${year}`;
  } else {
    return `${year}年${date.getMonth() + 1}月${day}日`;
  }
}

export { formatDate };
