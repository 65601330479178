const cozyCoffeeConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyAHZR_Wilv_70ZKpZ2FtiT-XYLJCy0pU3M",
    authDomain: "cozy-coffee-dd755.firebaseapp.com",
    projectId: "cozy-coffee-dd755",
    storageBucket: "cozy-coffee-dd755.appspot.com",
    messagingSenderId: "816687022255",
    appId: "1:816687022255:web:5e40c19b4a96660326945a",
  },
};

export default cozyCoffeeConfig;
