import { Card, CardMedia, Divider, makeStyles } from '@material-ui/core';
import { formatDate } from '../../../../libs/date';
import { useTranslation } from 'react-i18next';
import Gift from '../../../../assets/images/Gift';
import colors from '../../../../style/colors';
import useStore from '../../../../hooks/useStore';
import * as React from 'react';

const useStyles = makeStyles({
  itemContainer: {
    minWidth: 270,
    minHeight: 80,
    display: 'flex',
    marginBottom: 16,
    boxShadow: 'none',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  infoContainer: {
    marginLeft: 16,
    textAlign: 'left',
  },
  info: {
    lineHeight: 2,
    fontSize: 12,
    color: colors.text.light,
    fontWeight: 500,
  },
  info_red: {
    fontSize: 12,
    color: colors.deduct,
    fontWeight: 500,
  },
  image: {
    minWidth: 80,
    minHeight: 80,
    boxShadow: 'none',
    borderRadius: 5,
    backgroundColor: colors.grey,
  },
  imageContent: {
    objectFit: 'cover',
    width: 80,
    height: 80,
    backgroundColor: colors.grey,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: colors.grey10,
    marginBottom: 16,
  },
  imageOverlay: {
    borderRadius: 5,
    height: 80,
    width: 80,
    backgroundColor: colors.overlay,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gift: {
    width: 40,
    height: 40,
  },
});

export default function RewardItem(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { lang } = useStore();

  return (
    <>
      <Card className={classes.itemContainer}>
        <Card className={classes.image}>
          {data?.used_at && (
            <div className={classes.imageOverlay}>
              <Gift className={classes.gift} />
            </div>
          )}
          {data?.image[lang]?.url && (
            <CardMedia
              className={classes.imageContent}
              component="img"
              image={data?.image[lang]?.url}
              alt="image"
            />
          )}
        </Card>
        <div className={classes.infoContainer}>
          <span className={classes.title}>{data?.title[lang]}</span>
          <br />
          {data?.used_at && (
            <span className={classes.info}>
              {t('history.usedOn', { date: formatDate(data?.used_at, lang) })}
            </span>
          )}
          {data?.expire_at && !data?.used_at && (
            <span className={classes.info_red}>
              {t('history.expiredOn', {
                date: formatDate(data?.expire_at, lang),
              })}
            </span>
          )}
        </div>
      </Card>
      <Divider className={classes.divider} />
    </>
  );
}
